import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: _objectSpread({}, mapGetters(["currentPermission"])),
  mounted: function mounted() {
    var permisison = {
      admin_exchange_rate: this.checkPermission("admin_exchange_rate", 1),
      admin_address: this.checkPermission("admin_address", 1),
      admin_delivery: this.checkPermission("admin_delivery", 0),
      admin_bank: this.checkPermission("admin_bank", 0),
      admin_china_thai: this.checkPermission("admin_china_thai", 0),
      setting_notification_bar: this.checkPermission("setting_notification_bar", 0),
      admin_role: this.checkPermission("admin_role", 0),
      admin_address_china: this.checkPermission("admin_address_china", 1)
    };
    this.permisison = permisison;
  },
  data: function data() {
    return {
      permisison: {}
    };
  },
  methods: {
    getText: function getText() {
      var path = this.$route.path;

      switch (path) {
        case "/admin/seller/category":
          return "ประเภทสินค้า";

        default:
          return "กรุณาเลือก";
      }
    },
    checkPermission: function checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }

      var permissionRole = this.currentPermission.permissionRole;
      var find = permissionRole.find(function (x) {
        return x.permissionInfo && x.permissionInfo.permissionName === name;
      });

      if (!find) {
        return false;
      }

      if (type === 0 && find.readableRole) {
        return true;
      }

      if (type === 1 && find.writeableRole) {
        return true;
      }

      return false;
    }
  }
};